import {useHistory, useLocation}                   from "react-router-dom";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import React, {useState, useEffect}   from "react";
import {useTranslation}               from "react-i18next";
import DatePicker                     from "react-datepicker";
import { useForm } from 'react-hook-form';
import * as moment                    from 'moment';
import LoadingPage                    from "@/components/LoadingPage";
import {useAuth}                      from "@/services/Auth";
import LoadingButton                  from "@/components/LoadingButton";
import {Link}                         from "react-router-dom";
import { yupResolver }                from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select                         from "react-select";
import { Helmet } from "react-helmet";

function Addons({ site, booking, onSubmit, setBookingErrors, wait}) {
    const {t} = useTranslation();
    let auth = useAuth();

    const route = useLocation();
    const history = useHistory();

    const [availableServices, setServices] = useState(route.state && route.state.availableServices || false);

    const schema = yup.object().shape({
        addons: yup.array()
            .typeError(t('form_validation.addons_required_without_parking'))
            .when([], (schema) => {
                return booking.parking_booking && schema.nullable() ||
                    schema
                    .required(t('form_validation.addons_required_without_parking'))
                    .min(1,   t('form_validation.addons_required_without_parking'));
            }),
    }).required();

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        defaultValues: {
            ...(booking || {})
        },
        // mode: 'onBlur',
        // reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if(!availableServices)
        {
            const bookingData = JSON.parse(JSON.stringify(booking));
            bookingData.driver_phone_number = `+${bookingData.driver_phone_number}`;
            bookingData.shouldCheckSApaces = false;
            
            auth.postRequest('bookings/quote', bookingData)
                .then(response => {
                    setServices(response.data.available_services);
                })
                .catch(error => {
                    if(error.response.status == 422)
                    {
                        setBookingErrors(error.response.data.errors);
                    }
                })
        }

    }, [availableServices]);


    if(!availableServices)
    {
        return <LoadingPage />;
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="booking booking-addons container-fluid py-4">
            <Helmet>
                <title>{t('addons_services')} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <Row>
                <Col xl={8} xxl={6}>

                    <h1 className="h2">{t('create_a_booking')} {t('steps', { current: 2, steps: 4 })}</h1>

                    <Table bordered={true} className="my-4 small" style={{ "fontSize": '1rem' }}>
                        <tbody>
                        <tr>
                            <th>{t('site')}</th>
                            <td>{site.name}</td>
                        </tr>

                        {site.journey && (
                            <>
                            <tr>
                                <th>{t('distance')}</th>
                                <td>{site.journey.distance.text}</td>
                            </tr>
                            <tr>
                                <th>{t('journey_time')}</th>
                                <td>{site.journey.duration.text}</td>
                            </tr>
                            </>
                        )}
                        </tbody>
                    </Table>


                    <h3>{t('addons_services')}</h3>

                    <input
                        type="checkbox"
                        value={0}
                        {...register('addons[]')}
                        className="d-none"
                        />


                    <Row>
                    {availableServices?.map && availableServices.map((service, index) => (
                        <Col xs={12} md={6} lg={12} xxl={6}>
                        <Card className="my-3 small bg-light" key={index}>
                            <Card.Body>
                                <Card.Title as="h5">
                                    {service.name}
                                </Card.Title>

                                <p>
                                    {service.original_price != null && (
                                        <strike className="me-2">{service.original_price.toLocaleString(undefined, {minimumFractionDigits: 2})}</strike>
                                    )}

                                    <span>
                                        {service.price_with_vat.toLocaleString(undefined, {minimumFractionDigits: 2})}&nbsp;{site.currency}
                                        <span className="text-black-50 ms-auto small"> {t('incl_vat')}</span>
                                    </span>
                                </p>

                                {service.cap && (
                                    <div className="text-black-50 ms-auto small">
                                        {
                                            (service.cap.remaining === 'Unlimited') ? '' :
                                            (service.cap.remaining >= 0) ?
                                            <span>
                                                {t('remaining', {
                                                    figure: service.cap.remaining.toLocaleString(undefined, {minimumFractionDigits: 2}) + ' ' + (service.cap.type == 'Cost' ? site.currency : ''),
                                                    period: t(service.cap.period),
                                                })}
                                            </span> :
                                            <span className="text-info">
                                                {t('over_cap', {
                                                    figure: (-service.cap.remaining).toLocaleString(undefined, {minimumFractionDigits: 2}) + ' ' + (service.cap.type == 'Cost' ? site.currency : ''),
                                                    period: t(service.cap.period),
                                                })}
                                            </span>
                                        }
                                    </div>
                                )}
                            </Card.Body>

                            <Card.Footer className="pb-3">
                                <Form.Check>
                                <Form.Check.Label>
                                    <Form.Check.Input
                                        type="checkbox"
                                        className="me-2"
                                        value={service.site_service_id}
                                        {...register('addons[]')}
                                        />

                                    {t('add_to_order')}

                                </Form.Check.Label>
                                </Form.Check>
                            </Card.Footer>
                        </Card>
                        </Col>
                    ))}
                    </Row>

                    <Form.Control.Feedback type="invalid" className={!!errors.addons && 'd-block'}>
                        {errors.addons?.message}
                    </Form.Control.Feedback>


                    <div className="mt-4">
                        <Button type="submit" className="w-100" disabled={wait}>
                            {wait ? t('please_wait') : t('continue')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default Addons;
