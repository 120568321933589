import {Button, Modal}  from "react-bootstrap";
import {useTranslation} from "react-i18next";

function SuccessDialog({show, handleClose, message, type, RenderButton}) {

    const {t, i18n} = useTranslation();
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{type === 'ERROR' ? t('success_dialog.error') : t('success_dialog.success')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t(message)}</Modal.Body>
            <Modal.Footer>
                {
                    RenderButton
                        ? <RenderButton />
                        : (
                            <Button variant="primary" onClick={handleClose}>
                                {t('okay')}
                            </Button>
                        )
                }
            </Modal.Footer>
        </Modal>
    )
}

export default SuccessDialog;
