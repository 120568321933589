import {Button, Form, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useState} from "react";

function BookingPasscode(props) {

    const [passcode, setPasscode] = useState(null);

    const {t} = useTranslation();
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('booking.enter_booking_passcode')}</Modal.Title>
            </Modal.Header>
            <Form.Group controlId="bookingPasscode" className="m-4">
                <Form.Label>Booking Passcode</Form.Label>
                <Form.Control
                    placeholder="Enter booking passcode"
                    defaultValue={props.currentPasscode}
                    onChange={e => {
                        setPasscode(e.target.value);
                    }}
                    isInvalid={!!props.errors?.passcode}
                >
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {props.errors?.passcode}
                </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    {t(props.cancel)}
                </Button>
                <Button variant="primary" onClick={() => props.acceptAction(passcode)}>
                    {t(props.accept)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BookingPasscode;
